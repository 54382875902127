import { ArticleRestrictedContentHeader } from "@jti/loyalty/components/article-restricted-content/ArticleRestrictedContentHeader/ArticleRestrictedContentHeader";
import { Checkbox, ConsentCheckbox, ContactBlock, ContactForm, DiscountBlock, DoubleOptInRedirect, FaqList, FlavorFinder, HealthBanner, HeroBanner, HiddenPageRedirectionLayer, Input, LanguageSelector, NavigationStoreSelector, PrivacyForm, ProductCarousel, ProductSpecification, PromotionBlock, QrAgeVerificationContainer, ResponsiveImage, RtbCard, Search, Select, ShopLandingPage, StandardProductDetailsContainer, SticksProductDetailsContainer, StoreDetails, StoreLocator, TeaserCarousel, Textarea, UnsubscribeBlock, WelcomePopup, } from "@jti/rip";
import { BlogListing, ContentGate, ContentGateIP, ContentGatePopup, CtaBlock, Faq, FaqCategory, HeroContentAuthoringWrapper, HeroV2, ImageGallery, ImageGalleryTopic, List, MultiTab, PushBlock, Recaptchabar, SearchBox, SearchResults, Survey, TopFooterHealthBanner, VideoPlayer, } from "@jti/ui";
export default {
    faq: Faq,
    multiTab: MultiTab,
    "hero-banner": HeroBanner,
    "product-specification": ProductSpecification,
    "unsubscribe-block": UnsubscribeBlock,
    "language-selector": LanguageSelector,
    "privacy-form": PrivacyForm,
    "hidden-page-redirection-layer": HiddenPageRedirectionLayer,
    "footer-health-banner": TopFooterHealthBanner,
    "health-banner": HealthBanner,
    "store-locator": StoreLocator,
    "store-details": StoreDetails,
    checkbox: Checkbox,
    input: Input,
    contactform: ContactForm,
    textarea: Textarea,
    select: Select,
    "consent-checkbox": ConsentCheckbox,
    "video-player": VideoPlayer,
    "discount-block": DiscountBlock,
    "welcome-popup": WelcomePopup,
    "flavor-finder": FlavorFinder,
    "standard-product-details": StandardProductDetailsContainer,
    "sticks-product-details": SticksProductDetailsContainer,
    carousel: ProductCarousel,
    "responsive-image": ResponsiveImage,
    "qr-age-verification-popup": QrAgeVerificationContainer,
    "double-opt-in-redirect": DoubleOptInRedirect,
    recaptchabar: Recaptchabar,
    "shop-landing-page": ShopLandingPage,
    "navigation-store-selector": NavigationStoreSelector,
    "pushblock-new": PushBlock,
    "image-gallery": ImageGallery,
    "image-gallery-topic": ImageGalleryTopic,
    list: List,
    "hero-banner-v2": HeroContentAuthoringWrapper,
    "hero-banner-v2-container": HeroV2,
    search: Search,
    "search-box": SearchBox,
    "search-results": SearchResults,
    "promotion-block": PromotionBlock,
    "content-gate": ContentGate,
    "content-gate-ip": ContentGateIP,
    "content-gate-popup": ContentGatePopup,
    "contact-us-details-info": ContactBlock,
    "faq-category": FaqCategory,
    "faq-list": FaqList,
    "teaser-carousel": TeaserCarousel,
    "rtb-card": RtbCard,
    survey: Survey,
    blogheader: ArticleRestrictedContentHeader,
    ctablock: CtaBlock,
    blogv2: BlogListing,
};
